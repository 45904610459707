import { CompanyPermissions as companyPermissions } from './company-permissions';
import { Contacts as contacts } from './contacts';
import { Email as email } from './emails';
import { General as general } from './general';
import { Maps as maps } from './maps';
import { Marketing as marketing } from './marketing';
import { OfficePermissions as officePermissions } from './office-permissions';
import { TargetAudience as targetAudience } from './target-audience';

export const permissions = {
  companyPermissions,
  contacts,
  email,
  general,
  maps,
  marketing,
  officePermissions,
  targetAudience,
};
