import React, { ComponentType, useContext } from 'react';

import { AuthContext } from '../contexts';

export function withPermission(WrappedComponent: ComponentType, permission: string, message?: string) {
  return (props: any) => {
    const {
      authData: { permissions },
    } = useContext(AuthContext);
    if (permissions && permissions[permission]) {
      return <WrappedComponent {...props} />;
    }
    return <div>{message || 'Insufficient Permissions'}</div>;
  };
}

export const hasPermission = (permission: string) => {
  const {
    authData: { permissions },
  } = useContext(AuthContext);
  return permissions && permissions[permission];
};
