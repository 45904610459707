import React, { Component } from 'react';
import queryString from 'query-string';

import { AuthContext } from '../contexts';

export class Authenticate extends Component<any> {
  static contextType = AuthContext;

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const { token } = queryString.parse(search);
    const { login } = this.context;
    if (token) {
      login({ token });
    }
  }

  componentDidUpdate() {
    const {
      authData: { token },
    } = this.context;
    const {
      location: { search },
    } = this.props;
    const { origin } = queryString.parse(search) as { origin: string };

    if (token) {
      window.location.replace(origin || '/');
    }
  }

  render() {
    const {
      location: { search },
    } = this.props;
    const { token } = queryString.parse(search);
    if (!token) {
      return <div>Invalid Request</div>;
    }
    return <div>Authenticating</div>;
  }
}
