import Cookies from 'js-cookie';
import { ILoginResponse } from '../types/auth';

export const formatDataFromAPI = ({ token }: ILoginResponse): any => ({
  token,
});

export const getToken = (key: string): string | null => Cookies.get(key) || null;

export const saveToken = (key: string, token: string) => Cookies.set(key, token, { expires: 172800 });

export const handleAuthDataFromAPI = ({ token }: ILoginResponse, tokenKey: string): { token: string } => {
  saveToken(tokenKey, token);
  return {
    token,
  };
};
