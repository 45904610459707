export enum General {
  IS_OFFICE_DASHBOARD_AVAILABLE = 'isOfficeDashboardAvailable',
  VIEW_OFFICE_PROFILE = 'viewOfficeProfile',
  EDIT_OFFICE_PROFILE = 'editOfficeProfile',
  EDIT_USER_ROLES_PERMISSIONS = 'editUserRolesPermissions',
  INVITE_CREATE_DELETE_USERS = 'inviteCreateDeleteUsers',
  EDIT_OTHER_USER_PROFILES = 'editOtherUserProfiles',
  DISABLE_USERS = 'disableUsers',
  EDIT_PROPERTY_DETAILS = 'editPropertyDetails',
  VIEW_BILLING_DETAILS = 'viewBillingDetails',
  EDIT_BILLING_DETAILS = 'editBillingDetails',
}
