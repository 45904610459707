export enum Email {
  IS_EMAIL_AVAILABLE = 'isEmailAvailable',

  CREATE_USER_EMAIL_TEMPLATE_FOR_COMPANY = 'createUserEmailTemplateForCompany',
  CREATE_USER_EMAIL_TEMPLATE_FOR_OFFICE = 'createUserEmailTemplateForOffice',
  CREATE_USER_EMAIL_TEMPLATE_FOR_PRIVATE_USE = 'createUserEmailTemplateForPrivateUse',

  CREATE_MARKETING_EMAIL_TEMPLATE_FOR_COMPANY = 'createMarketingEmailTemplateForCompany',
  CREATE_MARKETING_EMAIL_TEMPLATE_FOR_OFFICE = 'createMarketingEmailTemplateForOffice',
  CREATE_MARKETING_EMAIL_TEMPLATE_FOR_PRIVATE_USE = 'createMarketingEmailTemplateForPrivateUse',
}
