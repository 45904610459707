export enum Contacts {
  IS_CONTACTS_AVAILABLE = 'isContactsAvailable',
  VIEW_INFORMATION_SET_TO_PRIVATE_IN_COMPANY = 'viewInformationSetToPrivateInCompany',
  VIEW_INFORMATION_SET_TO_PRIVATE_IN_OFFICE = 'viewInformationSetToPrivateInOffice',

  DELETE_INFORMATION_SET_TO_PRIVATE_IN_COMPANY = 'deleteInformationSetToPrivateInCompany',
  DELETE_INFORMATION_SET_TO_PRIVATE_IN_OFFICE = 'deleteInformationSetToPrivateInOffice',

  UPDATE_CONTACTS_SETTINGS = 'updateContactsSettings',

  UPDATE_CONTACT_INFORMATION = 'updateContactInformation',
}
