export enum CompanyPermissions {
  VIEW_COMPANY_PROFILE = 'viewCompanyProfile',
  EDIT_COMPANY_PROFILE = 'editCompanyProfile',

  VIEW_COMPANY_MARKETING = 'viewCompanyMarket ing',
  EDIT_COMPANY_MARKETING = 'editCompanyMarketing',

  VIEW_COMPANY_MEDIA = 'viewCompanyMedia',
  EDIT_COMPANY_MEDIA = 'editCompanyMedia',

  VIEW_LICENSING_BILLING_CARD_DETAILS = 'viewLicensingBillingCardDetails',
  EDIT_LICENSING_BILLING_CARD_DETAILS = 'editLicensingBillingCardDetails',

  VIEW_LICENSING_BILLING_LICENSES = 'viewLicensingBillingLicenses',
  EDIT_LICENSING_BILLING_LICENSES = 'editLicensingBillingLicenses',

  VIEW_LICENSING_BILLING_PAYMENTS = 'viewLicensingBillingPayments',

  VIEW_LICENSING_BILLING_BILLING_CONTACT = 'viewLicensingBillingBillingContact',
  EDIT_LICENSING_BILLING_BILLING_CONTACT = 'editLicensingBillingBillingContact',

  VIEW_COMPANY_USERS = 'viewCompanyUsers',
  EDIT_COMPANY_USERS = 'editCompanyUsers',
  ADD_NEW_COMPANY_USERS = 'addNewCompanyUsers',

  VIEW_API = 'viewApi',
  EDIT_API = 'editApi',

  VIEW_INTEGRATIONS = 'viewIntegrations',
  EDIT_INTEGRATIONS = 'editIntegrations',

  VIEW_TEMPLATES = 'viewTemplates',
  EDIT_TEMPLATES = 'editTemplates',

  VIEW_COMPANY_REPORTS = 'viewCompanyReports',
  EDIT_COMPANY_REPORTS = 'editCompanyReports'
}
