export enum TargetAudience {
  IS_TARGET_AUDIENCE_AVAILABLE = 'isTargetAudienceAvailable',

  CREATE_AUDIENCE_TEMPLATES_OF_OWN = 'createAudienceTemplatesOfOwn',

  CREATE_AND_EDIT_AUDIENCE_TEMPLATES_IN_OFFICE = 'createAndEditAudienceTemplatesInOffice',
  CREATE_AND_EDIT_AUDIENCE_TEMPLATES_IN_COMPANY = 'createAndEditAudienceTemplatesInCompany',

  ACCESS_PERSONAL_AUDIENCE_TEMPLATES_FOR_MARKETING_PURPOSES = 'accessPersonalAudienceTemplatesForMarketingPurposes',

  ACCESS_AUDIENCE_TEMPLATES_IN_OFFICE_FOR_MARKETING_PURPOSES = 'accessAudienceTemplatesInOfficeForMarketingPurposes',
  ACCESS_AUDIENCE_TEMPLATES_IN_COMPANY_FOR_MARKETING_PURPOSES = 'accessAudienceTemplatesInCompanyForMarketingPurposes',
}
