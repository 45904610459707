import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

const client = (uri: string, token: string | null) =>
  new ApolloClient({
    uri,
    request: (operation: any) => {
      operation.setContext({
        headers: {
          Authorization: token,
        },
      });
    },
  });

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

// eslint-disable-next-line react/display-name
export default ({ apiUrl, token, children }: { apiUrl: string; token: string | null; children: React.ReactNode }) => (
  <ApolloProvider client={client(apiUrl, token)}>{children}</ApolloProvider>
);
