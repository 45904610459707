import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const LOGOUT = gql`
  mutation($token: String!) {
    auth {
      logout(token: $token) {
        success
      }
    }
  }
`;

export const Logout = ({
  token,
  domainProtocol,
  domainSuffix,
}: {
  token: string;
  domainProtocol: string;
  domainSuffix: string;
}) => {
  const [logout] = useMutation(LOGOUT, {
    onCompleted: (data) => {
      if (data?.auth?.logout?.success) {
        window.location.replace(`${domainProtocol}auth.${domainSuffix}/login`);
      }
    },
  });
  return (
    <button onClick={() => logout({ variables: { token } })} className="header-menu-list-item">
      Logout
    </button>
  );
};
