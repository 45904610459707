/**
 * Currently All campaign permissions set at office level
 * Ref: https://virtual-capital.atlassian.net/wiki/spaces/UTOGI/pages/301170697/MarketingUserPermissions
 */

export enum Marketing {
  IS_MARKETING_AVAILABLE = 'isMarketingAvailable',

  EDIT_OFFICE_SETTINGS = 'editOfficeSettings',

  // Property Campaign
  CREATE_PROPERTY_CAMPAIGN = 'createPropertyCampaign',
  VIEW_OTHER_USER_PROPERTY_CAMPAIGN = 'viewOtherUserPropertyCampaign',
  EDIT_OTHER_USER_PROPERTY_CAMPAIGN = 'editOtherUserPropertyCampaign',
  RESTRICT_BEEN_PROPERTY_CAMPAIGN_OWNER = 'restrictBeenPropertyCampaignOwner',
  EDIT_PROPERTY_CAMPAIGN_SETTINGS = 'editPropertyCampaignSettings',

  // General Campaign
  CREATE_GENERAL_CAMPAIGN = 'createGeneralCampaign',
  VIEW_OTHER_USER_GENERAL_CAMPAIGN = 'viewOtherUserGeneralCampaign',
  EDIT_OTHER_USER_GENERAL_CAMPAIGN = 'editOtherUserGeneralCampaign',
  RESTRICT_BEEN_GENERAL_CAMPAIGN_OWNER = 'restrictBeenGeneralCampaignOwner',
}
