import { useContext } from 'react';

import { AuthContext } from '../contexts';

export const hasBetaAccess = (): boolean => {
  const {
    authData: { beta },
  } = useContext(AuthContext);
  return beta;
};
