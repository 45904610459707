import React from 'react';

import { AuthContextProvider } from '../contexts';
import { Session } from './session';

export const AuthProvider = ({
  children,
  callbackURL,
  appURL,
  ...props
}: {
  children: any;
  callbackURL: string;
  appURL: string;
  module: string;
  domainProtocol: string;
  domainSuffix: string;
}) => {
  const isAuthURL = window.location.pathname === callbackURL;
  const redirectURL = `${appURL}${callbackURL}`;
  return (
    <AuthContextProvider>
      {isAuthURL ? (
        children
      ) : (
        <Session redirectURL={redirectURL} {...props}>
          {children}
        </Session>
      )}
    </AuthContextProvider>
  );
};
