export enum Maps {
  IS_THE_MAPS_MODULE_AVAILABLE = 'isTheMapsModuleAvailable',
  EDIT_MAPS_OFFICE_SETTINGS = 'editMapsOfficeSettings',
  CREATE_NEW_MAPS = 'createNewMaps',
  VIEW_OTHER_USERS_CAMPAIGNS = 'viewOtherUsersCampaigns',
  EDIT_OTHER_USERS_CAMPAIGNS = 'editOtherUsersCampaigns',
  CREATE_EDIT_DELETE_PERSONAL_MAPS_TEMPLATES = 'createEditDeletePersonalMapsTemplates',
  CREATE_EDIT_DELETE_MAPS_TEMPLATES_FOR_OFFICE = 'createEditDeleteMapsTemplatesForOffice',
  CREATE_EDIT_DELETE_MAPS_TEMPLATES_FOR_COMPANY = 'createEditDeleteMapsTemplatesForCompany',
  RESTRICT_THIS_USER_ROLE_FROM_BEING_A_MAP_OWNER = 'restrictThisUserRoleFromBeingAMapOwner',
  VIEW_TEAM_VIEW = 'viewTeamView',
}
