import React, { useContext, useEffect, ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import queryString from 'query-string';

import GraphQL from '../graphql/client';
import { AuthContext } from '../contexts';
import { General } from '../enums/general';

const GET_PERMISSIONS = gql`
  query {
    auth {
      session {
        currentRole {
          title
          id
        }
        roles {
          title
          id
          office {
            name
          }
        }
        user
        permissions
        office {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
    user(scope: USER) {
      beta
    }
  }
`;

type PermissionHandlerProps = {
  redirectTo: string;
  module: string;
  domainProtocol: string;
  domainSuffix: string;
  setSession: Function;
  permissions: object | null;
  token: string | null;
};

const PermissionHandler: React.FC<PermissionHandlerProps> = ({
  children,
  permissions,
  setSession,
  redirectTo,
  module,
  token,
  domainProtocol,
  domainSuffix,
}) => {
  const { loading, data, error } = useQuery(GET_PERMISSIONS, { skip: permissions !== null });
  const origin = window.location.pathname;
  if (error) {
    window.location.replace(
      `${domainProtocol}auth.${domainSuffix}/login?${queryString.stringify({ redirectTo, module, origin })}`,
    );
  }
  useEffect(() => {
    if (data && !permissions) {
      const {
        auth: { session },
        user,
      } = data;
      const { permissions: userPermissions } = session;
      const payload = {
        ...session,
        links: {
          companySettings:
            userPermissions && userPermissions['isCompanyDashboardAvailable']
              ? `${domainProtocol}auth.${domainSuffix}/auth?${queryString.stringify({
                  token,
                  redirectTo: `${domainProtocol}company.${domainSuffix}/authenticate`,
                  module: 'COMPANY',
                })}`
              : null,
          officeSettings:
            userPermissions && userPermissions[General.IS_OFFICE_DASHBOARD_AVAILABLE] && session.office
              ? `${domainProtocol}auth.${domainSuffix}/auth?${queryString.stringify({
                  token,
                  redirectTo: `${domainProtocol}office.${domainSuffix}/authenticate`,
                  module: 'OFFICE',
                  role: session.currentRole ? session.currentRole.id : null,
                })}`
              : null,
          mySettings: `${domainProtocol}auth.${domainSuffix}/auth?${queryString.stringify({
            token,
            redirectTo: `${domainProtocol}profile.${domainSuffix}/authenticate`,
            module: 'PROFILE',
            role: session.currentRole ? session.currentRole.id : null,
          })}`,
          roles: session.roles.map(({ title, id: role, office: { name: office } }: any) => ({
            title,
            office,
            link: `${domainProtocol}auth.${domainSuffix}/auth?${queryString.stringify({
              ...(module !== 'COMPANY' && { module, redirectTo }),
              role,
              token,
            })}`,
          })),
        },
        beta: user?.[0]?.beta || false,
      };
      setSession(payload);
    }
  }, [data]);
  if (loading) {
    return <div>Loading</div>;
  }
  return <>{children}</>;
};

type SessionProps = {
  redirectURL: string;
  module: string;
  domainProtocol: string;
  domainSuffix: string;
  children: ReactNode;
};

export const Session = ({ children, redirectURL: redirectTo, module, domainProtocol, domainSuffix }: SessionProps) => {
  const origin = window.location.pathname;
  const authURL = `${domainProtocol}auth.${domainSuffix}/login?${queryString.stringify({
    redirectTo,
    module,
    origin,
  })}`;
  const {
    authData: { token, permissions },
    setSession,
  } = useContext(AuthContext);

  if (!token) {
    window.location.replace(authURL);
  }
  return (
    <GraphQL token={token} apiUrl={`${domainProtocol}api.${domainSuffix}/graphql`}>
      <PermissionHandler
        permissions={permissions}
        setSession={setSession}
        redirectTo={redirectTo}
        module={module}
        domainProtocol={domainProtocol}
        domainSuffix={domainSuffix}
        token={token}
      >
        {permissions ? children : <div>Loading</div>}
      </PermissionHandler>
    </GraphQL>
  );
};
